@use 'sass:map';
@use "@angular/material" as mat;

$app-typography: mat.define-typography-config($font-family: '"sansation", sans-serif;');

@mixin define-css-classes($theme) {

  @include mat.all-component-colors($theme);

  @include mat.typography-hierarchy($app-typography);

  @include mat.button-typography($app-typography);

  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  mat-sidenav {
    mat-icon {
      color: mat.get-color-from-palette($accent);
    }
  }

  mat-sidenav-content {
    background-color: lightgray;

    mat-icon {
      color: mat.get-color-from-palette($accent);
    }
  }
}

/**
* Define your custom themes in this map. 
* The `key` of each member is the name of CSS class for that theme. 
* To better understanding the schema of the map, see `@each` loop below and especially pay attention to 
* `map-has-key()` functions.
*/

$app-themes: (
  cargo-trans-remision: (primary-base: $mat-azul-ct,
    accent-base: $mat-naranja-ct,
  ),
  cargo-trans-seguimiento: (primary-base: $mat-naranja-ct,
    accent-base: $mat-azul-ct,
  ),
  cargo-trans-sistema: (primary-base: $mat-sistema-ct,
    accent-base: $mat-naranja-ct,
  )
);

@each $css-class,
$theme in $app-themes {
  $primary: if(map-has-key($theme, primary),
    map.get($theme, primary),
    mat.define-palette(map.get($theme, primary-base)));

  $accent: if(map-has-key($theme, accent),
    map.get($theme, accent),
    mat.define-palette(map.get($theme, accent-base)));

  $warn: if(map-has-key($theme, warn),
    map.get($theme, warn),
    mat.define-palette(if(map-has-key($theme, warn-base), map.get($theme, warn-base), mat.$red-palette)));

  .#{$css-class} {
    @include define-css-classes(mat.define-light-theme((color: (primary: $primary,
            accent: $accent,
            warn: $warn ),
        )));
  }

  .#{$css-class}-dark {
    @include define-css-classes(mat.define-dark-theme((color: (primary: $primary,
            accent: $accent,
            warn: $warn ),
        )));
  }

  .theme-primary.#{$css-class} {
    background-color: mat.get-color-from-palette($primary);
  }

  .theme-accent.#{$css-class} {
    background-color: mat.get-color-from-palette($accent);
  }

  .theme-warn.#{$css-class} {
    background-color: mat.get-color-from-palette($warn);
  }
}