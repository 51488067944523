@use "@angular/material" as mat;

@include mat.core();

@font-face {
  font-family: fsJoey;
  src: url(../assets/fonts/FS_Joey/fc7fd845ab9062a96aaa215295bf10b3.ttf) format("opentype");
}

@font-face {
  font-family: sansation;
  src: url(../assets/fonts/Sansation/Sansation_Regular.ttf) format("opentype");
}

$app-primary: mat.define-palette(mat.$indigo-palette, 900);
$app-accent: mat.define-palette(mat.$orange-palette, 700);
$app-warm: mat.define-palette(mat.$red-palette);

$app-typography: mat.define-typography-config($font-family: '"sansation", sans-serif;');

$app-theme: mat.define-light-theme((color: (primary: $app-primary,
        accent: $app-accent,
        warn: $app-warm,
      ),
      typography: $app-typography,
      density: 0,
    ));

@include mat.all-component-themes($app-theme);

@include mat.typography-hierarchy($app-typography);

@include mat.button-typography($app-typography);