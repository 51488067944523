@use '@angular/material' as mat;
@import "./themes/colores.scss";
@import "./themes/themes.scss";

*,
html,
body {
  font-family: "sansation", sans-serif;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

/* Scrollbar Style */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #7c878e;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #4c575f;
}

/* Correccion footer margin - formularios */
mat-card.dialog>form>mat-card-actions {
  padding-right: 16px;
  padding-bottom: 16px;
}


/* Mat Dialog Style */
.alert {
  width: 450px;
}

.form {
  width: 650px;
}

.global-mat-dialog {
  .mat-mdc-dialog-container {
    padding: 0;

    mat-toolbar {
      padding: 16px;
      height: auto;

      span {
        white-space: pre-wrap;
      }
    }

    mat-card.dialog {
      padding: 0;

      mat-card-content.dialog-container {
        margin-bottom: 0;
        padding: 16px;
        max-height: 65vh;
        overflow: auto;
      }

      mat-card-actions.dialog-footer {
        padding-right: 16px;
        padding-bottom: 16px;

        button {
          margin: 0;
        }
      }
    }
  }
}

mat-table.global-custom-table>mat-header-row>mat-header-cell {
  color: black !important;
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  .form {
    min-width: 100%;
    height: 100%;

    .mat-dialog-container {
      .mat-card.dialog {
        padding: 0;

        .mat-card-content.dialog-container {
          margin-bottom: 0;
          padding: 16px;
          max-height: 100%;
          overflow: auto;
        }

        .mat-card-actions.dialog-footer {
          margin: 0 16px;
          padding: 0;
          padding-bottom: 16px;
        }
      }
    }
  }
}

/** Digital POD Style **/
.pod-electronica-dialog {
  width: 700px;
  height: 90vh;

  .mat-dialog-container {
    padding: 0;
  }
}

@media screen and (max-width: 600px) {
  .pod-electronica-dialog {
    min-width: 100%;
    height: 100%;

    .mat-dialog-container {
      padding: 0;
    }
  }
}

/** AdBlock Style **/
.adblock-dialog {
  .mat-dialog-container {
    padding: 0;
  }
}

/* Toast Style */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #ffffff;
}

.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0 1px 0 #ffffff;
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #ffffff;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51a351;
}

.toast-error {
  background-color: #bd362f;
}

.toast-info {
  background-color: #2f96b4;
}

.toast-warning {
  background-color: #f89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}

@media screen and (max-width: 960px) {

  /* Mat Table Style */
  mat-table.global-custom-table {
    display: contents !important;
    border: 0;
    vertical-align: middle;

    mat-header-row {
      display: none !important;
    }

    mat-row {
      @include mat.elevation-transition();
      @include mat.elevation(5);
      border: none !important;
      display: block;
      margin-top: 1rem;

      &::after {
        display: none !important;
      }

      &:last-child {
        border: none;
        margin: 1rem 0;
      }

      mat-cell {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: 1em;
        text-align: right;
        font-weight: bold;
        width: 100%;
        padding: 16px 1rem;

        &::before {
          content: attr(aria-label);
          content: attr(data-label);
          float: left;
          font-weight: normal;
          font-size: 0.85em;
        }

        &.mat-column-accion {
          text-align: center !important;
          padding: 0px !important;

          button {
            margin-left: 6vw !important;
            margin-right: 6vw !important;
          }
        }
      }
    }

    mat-header-cell {
      border: 10px solid;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  }
}