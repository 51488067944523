/* For use in src/lib/core/theming/_palette.scss */
$mat-sistema-ct: (
    50: #e5e6e7,
    100: #bec2c3,
    200: #93999c,
    300: #677074,
    400: #475156,
    500: #263238,
    600: #222d32,
    700: #1c262b,
    800: #171f24,
    900: #0d1317,
    A100: #5bb9ff,
    A200: #28a3ff,
    A400: #008bf4,
    A700: #007dda,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-naranja-ct: (
    50: #fceae0,
    100: #f8cbb3,
    200: #f3a880,
    300: #ee854d,
    400: #ea6b26,
    500: #EA7600,
    600: #e34a00,
    700: #df4000,
    800: #db3700,
    900: #d52700,
    A100: #fffdfc,
    A200: #ffd0c9,
    A400: #ffa396,
    A700: #ff8d7d,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-azul-ct: (
    50: #e0e9ee,
    100: #b3c7d4,
    200: #80a2b8,
    300: #4d7c9c,
    400: #266086,
    500: #001871,
    600: #003e69,
    700: #00355e,
    800: #002d54,
    900: #001f42,
    A100: #77a8ff,
    A200: #4487ff,
    A400: #1167ff,
    A700: #0059f6,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #ffffff,
        A700: #ffffff,
    ),
);
